import * as React from 'react'
import * as footerStyles from './footerStyles.module.css'

const Footer = () => {
  return (
      <div className={footerStyles.footer}> 
        <p className={footerStyles.footerText}>Copyright Revi Inc, 2021</p>
      </div> 
  )
} 

export default Footer;