import * as React from 'react'
import * as teamStyles from './teamStyles.module.css'
import { StaticImage } from "gatsby-plugin-image"

const Team = () => {
  return (
    <>
        
        <div className={teamStyles.photos}>
            <h2>Meet the Team</h2>
            <div className={teamStyles.photoBlock}>
                <StaticImage src={'./imgs/sawyer.jpg'} alt="Sawyer Middeleer" className={teamStyles.teamPhoto} />
                <p className={teamStyles.teamCaption}>Sawyer Middeleer, Founder</p> 
            </div>
        </div>
    </>
  )
} 

export default Team;