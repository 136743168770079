import * as React from 'react'
import * as heroStyles from './heroStyles.module.css'

const Hero = () => {
  return (
      <div className={heroStyles.hero}>
        <h1>Revi Labs is a city tech studio built by a team of operators, designers, engineers and civic leaders on a mission to make our neighborhoods more vibrant and sustainable.</h1>
      </div>
    
  )
} 

export default Hero;