import * as React from 'react'
import * as formStyles from './formStyles.module.css'

const Form = () => {
  return (
    <div className={formStyles.formBlock}>
      <form className={formStyles.emailForm} name="email-form" method="post" netlify-honeypot="bot-field" data-netlify="true">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="email-form"/>
          <label className={formStyles.emailLabel}>
              <h3>Stay up to date on what we're building</h3>
              <input className={formStyles.emailInput} type="email" placeholder="Please enter your email" name="email" id="email" />
          </label>
          <button className={formStyles.formSubmit} type="submit">Submit</button>
      </form>
    </div>
  )
} 

export default Form;