import * as React from 'react'
import * as aboutBlockStyles from './aboutBlockStyles.module.css'

const AboutBlock = () => {
  return (
    <>
        <div className={aboutBlockStyles.aboutBlock}>
            <div className={aboutBlockStyles.aboutHeader}>
                <h2>We create and scale consumer products serving our communities</h2>
            </div>
            <div className={aboutBlockStyles.aboutItems}>
                <h3>Focus verticals</h3>
                <p>Commerce</p>
                <p>Built Environment</p>
                <p>Governance & Collective Action</p>
            </div>
        </div>
        <div className={aboutBlockStyles.aboutBlock}>
            <div className={aboutBlockStyles.aboutHeader}>
                <h2>Our work is driven by deeply-held values</h2>
            </div>
            <div className={aboutBlockStyles.aboutItems}>
                <h3>Our core principles</h3>
                <p>Build in partnership with customers and stakeholders</p>
                <p>Celebrate optimism, humility, creativity and persistence</p>
                <p>Always play positive sum games</p>
            </div>
        </div>
        <div className={aboutBlockStyles.clear}></div>
    </>
  )
} 

export default AboutBlock;