import * as React from 'react';
import * as navStyles from './navStyles.module.css';
import { StaticImage } from "gatsby-plugin-image"

const Navbar = () => {
  return (
      <div className={navStyles.nav}> 
        <StaticImage src={'./imgs/full_logo.png'} alt="Logo" width={400} height={100} />
      </div> 
  )
} 

export default Navbar;