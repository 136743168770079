import * as React from 'react'
import Hero from '../components/hero/hero'
import Form from '../components/form/form'
import AboutBlock from '../components/aboutBlock/aboutBlock'
import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
import Team from '../components/team/team'

const IndexPage = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <AboutBlock />
      <Form />
      <Team />
      <Footer />
    </>
  )
}

export default IndexPage